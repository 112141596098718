@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $format: "woff") {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
    @if $format == "woff" {
      src: url("#{$file-path}.#{$format}") format($format);
    } @else {
      src: url("#{$file-path}.#{$format}") format("truetype");
    }
  }
}

@include font-face("Helvetica Neue", "../assets/fonts/HelveticaNeueCyr-Light", 300, light, "ttf");
@include font-face("Helvetica Neue", "../assets/fonts/HelveticaNeueCyr-Roman", 400, normal, "ttf");
@include font-face("Helvetica Neue", "../assets/fonts/HelveticaNeueCyr-Medium", 500, medium, "ttf");
@include font-face("Helvetica Neue", "../assets/fonts/HelveticaNeueCyr-Bold", 700, bold, "ttf");

@include font-face("Inter", "../assets/fonts/Inter-Medium", 500, medium, "ttf");
@include font-face("Inter", "../assets/fonts/Inter-SemiBold", 600, semi-bold, "ttf");
@include font-face("Inter", "../assets/fonts/Inter-Bold", 700, bold, "ttf");
