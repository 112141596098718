body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  background: #F4F0E7 !important;
  span,
  & > #root {
    overflow-x: hidden;
  }
  div {
    font-feature-settings: 'liga' off;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
}
